/* eslint-disable import/no-unresolved */
import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

// Elements
import LinkButton from 'components/elements/LinkButton'

// Shared
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'

const Content = styled(ParseContent)`
  min-height: 230px;
`

const Cases = ({ posts }) => (
  <div className="row">
    {posts.map(({ node }) => (
      <div
        key={node.wordpress_id}
        className="col-md-6 col-lg-4 p-lg-4 d-flex justify-content-center"
      >
        <Case fields={node} />
      </div>
    ))}
  </div>
)

export default Cases

const BlogContainer = styled.div`
  @media (min-width: 1200px) {
    min-width: 350px;
    max-width: 450px;
  }

  @media (max-width: 1199px) {
    min-width: 300px;
    max-width: 350px;
  }

  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`

const BlogImage = styled(Image)`
  height: 250px;
  background: ${(props) => props.theme.color.face.gray};
`

const BlogContent = styled.div`
  background: ${(props) => props.theme.color.face.light};
  
  h4 {
    font-size: ${(props) => props.theme.font.size.l};
    color: ${(props) => props.theme.color.text.main};
    font-family: ${(props) => props.theme.font.family.main};
    min-height: 130px;
  }

  p {
    font-size: ${(props) => props.theme.font.size.sm};
    font-weight: ${(props) => props.theme.font.weight.m};
    line-height: ${(props) => props.theme.font.size.l};
    color: ${(props) => props.theme.color.text.main};
    font-family: ${(props) => props.theme.font.family.secondary};
  }

  @media screen and (max-width: 768px) {
    p {
      font-weight: ${(props) => props.theme.font.weight.m};
    }
  }
`

const Case = ({ fields }) => (
  <BlogContainer>
    <Link to={fields.path}>
      <BlogImage src={fields.acf.preview.thumbnail} />
    </Link>

    <BlogContent className="p-4">
      <h4 className="pb-3">{fields.title}</h4>
      <Content content={fields.acf.preview.description} />
      <div className="py-lg-3 py-2" />
      <LinkButton to={fields.path} title="Lees verder" />
    </BlogContent>
  </BlogContainer>
  )
