/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Images
import BackImg from 'img/back-logo.png'
import ShineImg from 'img/shine.png'

// Shared
import { ParseContent, Image, SEO } from 'components/shared'

// Layout
import Layout from 'components/layout/Layout'

// Blog
import Cases from 'components/related/Cases'
import Blogik, { BlogConsumer, BlogButton } from 'components/shared/Blogik'

const Content = styled(ParseContent)`
  h1 {
    br {
      display: none;
    }
  }

  h4,
  h5 {
    letter-spacing: 2px;
  }

  h4 {
    color: rgba(197, 197, 197, 0.4);
    font-size: ${(props) => props.theme.font.size.m};
  }

  & table {
    width: 100%;

    td {
      padding-right: 30px;
      vertical-align: top;
    }

    p {
      font-size: ${(props) => props.theme.font.size.sm};
    }
  }
`

const UBXImage = styled.div`
  position: absolute;
  width: 80%;
  margin-top: 0;
  background-size: contain !important;
  background-position-x: 0 !important;

  @media screen and (max-width: 768px) {
    margin-top: 0;
    width: 100%;
  }

  & .gatsby-image-wrapper {
    max-height: 250px;
    overflow: initial !important;

    & img {
      max-height: 350px;
      object-fit: contain;
    }
  }
`

const StyledBlogButton = styled(BlogButton)`
  background-color: ${(props) => props.theme.color.face.contrast};
  padding: 10px 20px 10px 20px;
  border-radius: 40px;
`

const ShineImage = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => (
  <Layout activeMenu="Cases">
    <SEO yoast={yoast} path={path} />
    
    <div className="py-lg-5 py-4" />

    <div className="container">
      <Content content={acf.banner.description} />
    </div>

    <div className="pb-5" />
    {/* SHINE */}
    <ShineImage>
      <Image src={ShineImg} style={{ width: '100%' }} />
    </ShineImage>

    {/* UBX BACKGROUND IMAGE */}
    <UBXImage>
      <Image src={BackImg} style={{ width: '100%' }} />
    </UBXImage>

    {/* CASE OVERVIEW */}
    <Blogik
      settings={{
        limit: null,
        postType: 'case',
      }}
    >
      <BlogConsumer>
        {({ hasPosts, posts, showMoreButton, showLessButton }) => (
          <div className="py-lg-5 py-3 mt-lg-5 mt-3">
            {hasPosts && (
              <div className="container py-lg-5 py-3">
                <Cases posts={posts} />
              </div>
            )}

            <div className="text-center mt-5">
              {showMoreButton && (
                <div className="d-flex justify-content-center">
                  <StyledBlogButton>Meer tonen</StyledBlogButton>
                </div>
              )}
              {showLessButton && (
                <div className="d-flex justify-content-center">
                  <StyledBlogButton increment={false}>
                    Minder tonen
                  </StyledBlogButton>
                </div>
              )}
            </div>
          </div>
        )}
      </BlogConsumer>
    </Blogik>

    <div className="py-5" />
  </Layout>
)

export default PageTemplate

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          description
          animation
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

